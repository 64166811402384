body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #14213D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rbc-calendar{
  height: 100vh;
}

.rbc-event{
  height: auto !important;
}

.rbc-header{
  height: 2em;
}

.rbc-header.rbc-today {
  background-color: #5B6477;
}

.rbc-day-bg{
  background-color: #5B6477;
}

.rbc-selected{
  background-color: green !important;
}
.rbc-agenda-time-cell{
  width: 20%;
}

.rbc-toolbar-label{
    font-size: 1.5em;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

.rbc-btn-group button {
  color: white;
  font-weight: bold;
}

.rbc-btn-group .rbc-active{
  color: black;
}

.rbc-agenda-empty{
  color: white;
  font-size: 1em;
  text-align: center;
}

.rbc-time-slot .rbc-label{
  color: white;
  font-size: 1em;
}

.rbc-header{
  font-size: 1em;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

/* Modal */
.ReactModalPortal > div{
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity .5s ease-in-out;
  z-index: 999;
}
.rbc-events-container, .rbc-row{
  background-color: #121E37;
}
.modal-fondo {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  background: white;
  border-radius: 5px;
  color: rgb(51, 51, 51);
  display: inline;
  max-height: 620px;
  max-width: 500px;
  outline: none;
}

.react-datetime-picker__wrapper{
  display: flex;
  border: none;
}

.react-datetime-picker__wrapper{
  display: flex;
  border: none;
}

.table__header{
  display: grid;
  grid-template-columns: 10% 20% 20% 15% 30% 5%;
}

.table__header--bonos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

button:active {outline: 0 !important;}
button:focus {outline:0 !important;}

.uid-menu{
  font-size: .6em;
  margin-top: -5px;
}

.dropdown-menu{
  top: 5em;
}

.dropdown-menu a {
  font-size: .7em;
  text-transform: uppercase;
}

.dropdown-menu button {
  font-size: .7em;
  text-transform: uppercase;
}

.dropdown-menu hr {
  border-top: 1px solid #5B6477;
}

.alert-header {

  font-size: .8em;

}

.user-modal__bonos{
  grid-template-columns: 70% 30%;
  display: inline-grid;
  gap: 1em;
 justify-content: space-between;
  width: 100%;
}

.clase__usuarios-registrados{
  min-height: 6.5em;
}

